import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './App';
import Star from './Star';
import Home from "./Home";
import Tms from "./Tmss";

 

//,Navigate <Route path="/" element={<Navigate to="https://trackmysona.com/" />} />
const MainRoute = () => {
  return ( 
    <Router>
      <Routes> 
        <Route  path="track/:dbname/:qrcode" element={<App />} />
        <Route  path="track/:dbname/:qrcode/:compid/ratings" element={<Star />} />
        <Route  path="/thanks" element={<Home />} /> 
        
        <Route  path="/" element={<Tms />} /> 
      </Routes>
    </Router>
  );
}

export default MainRoute;
