import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import './App.css';

function App() {
  const navigate = useNavigate();
  const { dbname, qrcode } = useParams();
  const [apiData, setApiData] = useState(null); 
  const [logo, setLogo] = useState(null); 
  const [enableButton, setenableButton] = useState(true); 
  const [enableProductInfo, setenableProductInfo] = useState(false); 
  const [otp, setOtp] = useState(false); 

  useEffect(()=>{
    axios.post("https://trackmysona.com:3001/product_master/qrcode", {
      prdQRCode: qrcode,
      dbname: dbname
})
.then(response => {
  if(response.data.result===true) {
    if(response.data.productInfo===true){
      setenableProductInfo(true);
    } else {
      setenableProductInfo(false);
    }
    console.log(response.data.product[0]);
    setLogo(response.data.logo);
    setApiData(response.data.product[0]);
  } else {
    alert (response.data.message);
    window.location.href="https://trackmysona.com/";
  }
})
.catch(error => {
  console.error('Error sending data:', error);
});

  },[dbname, qrcode])

      // date
      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; 
      let dd = today.getDate();
      
      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;
      
  
  const date2 =dd + '-' + mm + '-' + yyyy;
  
  
  //Time
  var todaytime = new moment();
  console.log(todaytime.format("HH:mm"));
  var time1 =todaytime.format("HH:mm");


  const statuses = [
    'Not Working', 
    'Broken',
    'Low Voltage',
    'Bursted'
  ];

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    complaint:'',
    selectoption:''
  });

  const formChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };



  const NullData = (data) => {
   if(data===null)
   {
    return "Null"
   }else{
    return data
   }
  };

  var otparr = [];


const getotp=()=>{

var randomnumber = Math.floor(100000 + Math.random() * 900000);

let message = "One time password (OTP) for your complaint registration is " +randomnumber+ " Do not share it with anyone for security reasons - SONA STAR."
  
// let ab = "One time password (OTP) for your complaint registration is {#var#} . Do not share it with anyone for security reasons - SONA STAR."

// $.ajax({
//   url: 'https://smshorizon.co.in/api/sendsms.php?user=Sonatech&apikey=Y7VVzSPtX3vfsq5AKYCG&mobile=' +formData.phone+ '&senderid=SONASR&type=txt&tid=1507167447525335119&message='+message,
//   success: function(data){
//       alert(data);
//   }
// });

axios.get('https://smshorizon.co.in/api/sendsms.php', {
  params: {
    user: 'Sonatech',
    apikey: 'Y7VVzSPtX3vfsq5AKYCG',
    mobile: formData.phone,
    senderid: 'SONASR',
    type: 'txt',
    tid: '1507167447525335119',
    message: message
  }
})
.then(response => {
  setOtp(true)
  alert(response.data);
})
.catch(error => {
  setOtp(true)
  console.error('Error sending SMS:', error);
});

toast.success("OTP is send to your mobile number")

//show otp div
document.getElementById("otp-div").style.display = 'inline';


otparr.push(randomnumber);

//localstorage
var localStorage = window.localStorage;
localStorage.setItem('randomnumber', randomnumber.toString());
}


const [otp1, setOtp1] = useState('');
const [isButtonDisabled, setIsButtonDisabled] = useState(true);

useEffect(() => {
  setIsButtonDisabled(!otp1); // Disable button if otp is empty
}, [otp1]); // This effect runs every time otp changes

const handleOtpChange = (event) => {
  setOtp1(event.target.value);
};

const handleVerifyClick = () => {
  // Logic for verification
  const otpverify = localStorage.getItem('randomnumber');

  if(otp1===otpverify)
  {
    toast.success("Otp verified successfully");
    setenableButton(false)
    
  }else{
    toast.error("Invalid Otp");
    setenableButton(true)
  }

};


  const validateForm = () => {
    
    if (!formData.name) {
      toast.error("Name is required");
      return false;
    }
    if (!formData.phone) {
      toast.error("Phone number is required");
      return false;
    }
    if (!formData.selectoption) {
      toast.error("complaint Type is required");
      return false;
    }
    if (!formData.complaint) {
      toast.error("complaint  is required");
      return false;
    }
    // Add more validations as needed

    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      setenableButton(true);
      // Form is valid, proceed with form submission logic
   

      axios.post("https://trackmysona.com:3001/complaints/new",   {
        "dbname":dbname,
        "productQRCode":qrcode,
        "complaintTypeID":'0',
        "detailsOfComplaint":formData.selectoption + ' / ' + formData.complaint,
        "complaintBy":'0',
        "mobileNo":formData.phone,
        "name":formData.name,
        "mobileVerificationStatus":'yes',
        "createdBy":'0',
        "departmentID":apiData.departmentID,
        "productName":apiData.productName,
        "complaintFrom":'web'
    })
  .then(response => {
    
    console.log(response.data)
    if(response.data.result===true) {
      navigate('/thanks');
      toast.success("Form submitted successfully");
    
    } else {
      toast.success("Complaint not updated. Try again");
      setenableButton(false)
    }
  })
  .catch(error => {
    console.error('Error sending data:', error);
    setenableButton(false)
  });
      
      // Add your submission logic here
    }
  };

  return ( 
    <div className="container">
      <span class="big-circle"></span>
      {apiData &&<div class="form">
    
    { 
    enableProductInfo===true 
     ? <div class="contact-info"> <div>
     <h3>Product Details</h3>
     <Box
component="form"
sx={{
'& > :not(style)': { m: 1, width: '25ch' },
}}
noValidate
autoComplete="off"
>
<TextField id="outlined-basic" label="Product Name"  value={NullData(apiData.productName)} variant="outlined" />
<TextField id="outlined-basic" label="Department of Product"  value={NullData(apiData.departmentName)} variant="outlined" />
<TextField id="ins_by" label="Installed By" variant="outlined" value={NullData(apiData.InstalledBy)} margin="normal" fullWidth />
<TextField id="ins_date" label="Installation Date" variant="outlined" value={NullData(apiData.InstalledDate)} margin="normal" fullWidth />
<TextField id="location" label="Location" variant="outlined" value={NullData(apiData.location)} margin="normal" fullWidth />
<TextField id="make" label="Make" variant="outlined" value={NullData(apiData.brand)} margin="normal" fullWidth />
<TextField id="mob" label="Mobile" variant="outlined" value="Null"  margin="normal" fullWidth />
<TextField id="model" label="Model" variant="outlined" value={NullData(apiData.productDescription)} margin="normal" fullWidth />
<TextField id="power_rating" label="Power Rating" variant="outlined" value="Null" margin="normal" fullWidth />
<TextField id="procurement" label="Procurement" variant="outlined" value="Null" margin="normal" fullWidth />
<TextField id="sn_no" label="Serial Number" variant="outlined" value={NullData(apiData.productID)} margin="normal" fullWidth />
<TextField id="wexpiry"label="Warranty Expiry" variant="outlined" value={NullData(apiData.WarrantyExpirydate)} margin="normal" fullWidth />
<TextField id="wperiod" label="Warranty Period" variant="outlined" value={NullData(apiData.WarrantyPeriod)} margin="normal" fullWidth />
<TextField id="config" label="Configuration" variant="outlined" value="Null" margin="normal" fullWidth />
<TextField id="bname" label="Brand Name" variant="outlined" value="Null" margin="normal" fullWidth />
<TextField id="floor" label="Floor" variant="outlined" value={NullData(apiData.locationName)}  margin="normal" fullWidth />
<TextField id="escalated1" label="Escalated 1" variant="outlined" value="Null" margin="normal" fullWidth/>
<TextField id="escalated2" label="Escalated 2" variant="outlined" value="Null" margin="normal" fullWidth />
<TextField id="commentSupervisor" label="Supervisor Comment" value="Null" variant="outlined" margin="normal" fullWidth />
<TextField id="CommentAdmin" label="Admin Comment" value="Null" variant="outlined" margin="normal" fullWidth />
<TextField id="date" label="Date" variant="outlined" value={date2} margin="normal" fullWidth />
<TextField id="time" label="Time" variant="outlined" value={time1} margin="normal" fullWidth />
</Box>


</div>  </div>
     : null 
   }
           

         
 
   
    
    <div class="contact-form">
    <div class="logoinformation">
        <img src={logo} class="icon" alt="" />
         
      </div>
        <form id="com_form" onSubmit={handleSubmit} >
          <h3 class="title">File Your Complaints</h3>
          <div class="input-container">
            <input id="demo1" type="text" name="name" class="input" value={qrcode} disabled />
            <span>ID</span>
          </div>
          <div class="input-container">
            <input type="text" name="name" class="input" placeholder="Name *"     value={formData.name} 
        onChange={formChange}   id="com_by_name" />
          </div>

          <div class="otp">

<div class="input-container">
  <input type="number" name="phone" class="input" id="com_by_mob" placeholder='Phone Number *'     value={formData.phone} 
        onChange={formChange}  pattern="[7-9]{1}[0-9]{9}" />
        {formData.phone==="" ? (<button type="button" class="btn" id="getotp" disabled={true}> Get OTP</button>):(<button type="button" class="btn" id="getotp" onClick={getotp}> Get OTP</button>)}
  

      </div>
      <p id="smstext" class="smstext"></p>
    
        <div class="otp-div" id="otp-div">
        {otp===true &&
        <>
         <div className="input-container">
      <input
        type="number"
        name="phone"
        className="input"
        id="otpnum"
        placeholder='Enter Otp' 
        value={otp1}
        onChange={handleOtpChange}
      />
   
      <button
        type="button"
        className="btn"
        id="verify"
        disabled={isButtonDisabled}
        onClick={handleVerifyClick}
      >
        Verify
      </button>
    </div>
        <p id="otptext" class="otptext"></p>
        </>
      }
      </div>
    
          
    
     </div>
          <select class="select-input" id="select_txt"  name="selectoption"     value={formData.selectoption} 
        onChange={formChange} >
          <option value="" disabled selected>
    Complaint Type
  </option>
        {statuses.map((status, index) => (
       <option key={index} value={status}>{status}</option>
       ))}
      </select>

      <div  id="pDetails">
      <div class="input-container">
        <input type="text" class="input" placeholder='Enter Complaint' id="com_txt_others"  name="complaint"  value={formData.complaint} 
        onChange={formChange} />
       
      </div>
    </div>
      
      <button type="submit" class="btn" id="insert" disabled={enableButton}  > Submit</button>
      <ToastContainer position="top-center" autoClose={5000} />
         </form>
         </div>
         </div>
         
         }
    
    <div id="footer">
      <div class="information">
        <img src="./assets/TMSlogo.jpg" class="icon" alt="" />
        <p>Powered By Sona Star Innovations Pvt Limited.</p>
      </div>
    </div>
    </div>
  );
}

export default App;
