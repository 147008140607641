import { useState } from "react";
import './App.css';
import { FaStar } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
const colors = {
    orange: "#FFBA5A",
    grey: "#a9a9a9"
    
};



function App() {
  const navigate = useNavigate();
  const [apiData, setApiData] = useState(null);
  const [currentValue, setCurrentValue] = useState(0);
  // const [review, setReviewValue] = useState(0);
  const [review, setReview] = useState(0);
  const [hoverValue, setHoverValue] = useState(undefined);
  const { dbname, qrcode, compid } = useParams();
  const stars = Array(5).fill(0)

  const handleClick = value => {
    console.log(value);
    setCurrentValue(value)
  }

  const handleChange = (event) => {
    setReview(event.target.value);
    console.log(review);
};

  const handleMouseOver = newHoverValue => {
    setHoverValue(newHoverValue)
  };

   

  const handleMouseLeave = () => {
    setHoverValue(undefined)
  }
  const validateForm = () => {
    
    if (!currentValue) {
      toast.error("Select your rating");
      return false;
    }
      
    return true;
  };
  const setRate = (event) => {
    event.preventDefault();
    console.log(currentValue);
    console.log(review);
    if (validateForm()) {
      // Form is valid, proceed with form submission logic 
      axios.post("https://trackmysona.com:3001/complaints/ratings",   {
        "dbname":dbname,
        "productQRCode":qrcode,
        "complaintID":compid, 
        "ratingVal":currentValue, 
        "review":review
    })
  .then(response => { 
    console.log(response.data)
    if(response.data.result===true) {
      navigate('/thanks');
      toast.success("Form submitted successfully");
    
    } else {
      toast.success("Review not updated. Try again");
    }
  })
  .catch(error => {
    console.error('Error sending data:', error);
  });
      
      // Add your submission logic here
    }
  };


  return (
    <div style={styles.container}>
      <div class="info"><div class="information"><img src="./assets/TMSlogo.jpg" class="icon" alt="" />
         </div></div>
      <h2> Your Valuable Feedback  </h2><br/>
      <div style={styles.stars}>
        {stars.map((_, index) => {
          return (
            <FaStar
              key={index}
              size={24}
              onClick={() => handleClick(index + 1)}
              onMouseOver={() => handleMouseOver(index + 1)}
              onMouseLeave={handleMouseLeave}
              color={(hoverValue || currentValue) > index ? colors.orange : colors.grey}
              style={{
                marginRight: 10,
                cursor: "pointer"
              }}
            />
          )
        })}
      </div>
      <textarea
        placeholder="Your Valuable Feedback"
        name="review"
        onChange={handleChange}
        style={styles.textarea}
      />

      <button
        style={styles.button} id="setRate" onClick={setRate}
      >
        Submit
      </button>
      
    </div>
  );
};


const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  stars: {
    display: "flex",
    flexDirection: "row",
  },
  textarea: {
    border: "1px solid #a9a9a9",
    borderRadius: 5,
    padding: 10,
    margin: "20px 0",
    minHeight: 100,
    width: 300
  },
  button: {
    border: "1px solid #a9a9a9",
    borderRadius: 5,
    width: 300,
    padding: 10,
  }

};




export default App;
 