import React, { useEffect } from 'react';
import "./Tms.css"

const Tmss = () => { 
  useEffect(() => {
    // This function will be called when the component is mounted

    // Retrieve phone number from localStorage
    const x = localStorage.getItem('phn');
    console.log(x);

    // const URL_tms = 'https://www.sonastar.in/';

    // Construct the message
    // const message =
    //   'Thank you. Your complaint has been registered successfully. For more details click here ' +
    //   URL_tms +
    //   ' - SONA STAR';

    // Uncomment the following lines when using AJAX, for now, I'll use console.log
    // fetch(
    //   'https://smshorizon.co.in/api/sendsms.php?user=Sonatech&apikey=Y7VVzSPtX3vfsq5AKYCG&mobile=' +
    //     x +
    //     '&senderid=SONASR&type=txt&tid=1507167447559320708&message=' +
    //     message
    // )
    //   .then((response) => response.json())
    //   .then((data) => {
    //     alert(data);
    //     console.log('thanksms');
    //   });

    // Simulate AJAX call with console.log
    console.log('Simulating AJAX call');

    // Cleanup function (will be called when the component is unmounted)
    return () => {
      console.log('Component unmounted');
    };
  }, []); // Empty dependency array means this effect runs once, similar to window.onload

  return (
    <div className="comming-soon">
		<div className="comming-soon-info">
			<div className="comming-soon-inner">
				<div className="logo">
					<img src="https://sonastar.com/wp-content/uploads/2023/01/TMS-Logo-Final.jpg" alt="Track My Sona Logo" style={{paddingTop:'50px', maxWidth:'25%'}}  />
				</div>
				
				<div className="site-info">
					<div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-e8209ae" data-id="e8209ae" data-element_type="column">
			<div className="elementor-widget-wrap elementor-element-populated">
								<div className="elementor-element elementor-element-34860fe elementor-widget elementor-widget-heading" data-id="34860fe" data-element_type="widget" data-widget_type="heading.default">
				 
				</div>
				<div className="elementor-element elementor-element-ae339ec shadow-text animated-slow elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list animated fadeIn" data-id="ae339ec" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="icon-list.default">
				<div className="elementor-widget-container">
					<ul className="elementor-icon-list-items">
							<li className="elementor-icon-list-item">
											 
										<span className="elementor-icon-list-text">First ORP platform on mobile application</span>
									</li>
								<li className="elementor-icon-list-item">
											 
										<span className="elementor-icon-list-text">Pinpoint problem identification, asset tracking, inventory management, 24/7 access,  three-stage escalation matrix with unique QR codes for monitoring</span>
									</li>
								<li className="elementor-icon-list-item">
											 
										<span className="elementor-icon-list-text">User-friendly &amp; compatible with Android phone scanner</span>
									</li>
								<li className="elementor-icon-list-item">
											 
										<span className="elementor-icon-list-text">Ticket raised only with OTP verification</span>
									</li>
								<li className="elementor-icon-list-item">
											 
										<span className="elementor-icon-list-text">CUG possible for restricted access.</span>
									</li>
								<li className="elementor-icon-list-item">
											 
										<span className="elementor-icon-list-text">Specific window access to complainants</span>
									</li>
								<li className="elementor-icon-list-item">
										 
										<span className="elementor-icon-list-text">Beneficial for educational institutions, industries, hotels, malls, airports, railway stations, apartments, theme parks, etc.</span>
									</li>
						</ul>
				</div>
				</div>
					</div>
		</div>
				</div>
				
				
				 
				     <a href="https://sonastar.com/track-my-sona/">
				<button type="button" className="btn btn-funds">More details</button>    
				</a>   
					 
				 
				
				<div className="contact-info">
					<span>info@sonastar.com</span>
					<span>(+91) 94425 92144</span>
					<div className="social-link">
						<a href="#"><i className="fa fa-facebook-square"></i></a>
						<a href="#"><i className="fa fa-twitter-square"></i></a>
						<a href="#"><i className="fa fa-linkedin-square"></i></a>
						<a href="#"><i className="fa fa-pinterest-square"></i></a>
						<a href="#"><i className="fa fa-instagram"></i></a>
					</div>
				</div>
			</div>
		</div>
		
		<div className="coming-soon-image">
			 
		</div>
	</div>
  );
};

export default Tmss;
