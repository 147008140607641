import React, { useEffect } from 'react';
import "./home.css"

const Home = () => {
  useEffect(() => {
    // This function will be called when the component is mounted

    // Retrieve phone number from localStorage
    const x = localStorage.getItem('phn');
    console.log(x);

    // const URL_tms = 'https://www.sonastar.in/';

    // Construct the message
    // const message =
    //   'Thank you. Your complaint has been registered successfully. For more details click here ' +
    //   URL_tms +
    //   ' - SONA STAR';

    // Uncomment the following lines when using AJAX, for now, I'll use console.log
    // fetch(
    //   'https://smshorizon.co.in/api/sendsms.php?user=Sonatech&apikey=Y7VVzSPtX3vfsq5AKYCG&mobile=' +
    //     x +
    //     '&senderid=SONASR&type=txt&tid=1507167447559320708&message=' +
    //     message
    // )
    //   .then((response) => response.json())
    //   .then((data) => {
    //     alert(data);
    //     console.log('thanksms');
    //   });

    // Simulate AJAX call with console.log
    console.log('Simulating AJAX call');

    // Cleanup function (will be called when the component is unmounted)
    return () => {
      console.log('Component unmounted');
    };
  }, []); // Empty dependency array means this effect runs once, similar to window.onload

  return (
    <div className="box">
      <h1>Thank You !</h1>
      <p>
        Your complaint is registered successfully. <br /> You will be acknowledged once the complaint is solved.
      </p>
      <div className="pyro">
        <div className="before"></div>
        <div className="after"></div>
      </div>
    </div>
  );
};

export default Home;
